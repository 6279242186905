export const questions = {
    firstName: {
        title: "First name",
    },
    lastName: {
        title: "Last name",
    },
    middleName: {
        title: "Middle name (if applicable)",
    },
    dateOfBirth: {
        title: "Date of birth",
    },
    countryOfBirth: {
        title: "Place of birth (country)",
    },
    citizenship: {
        title: "Citizenship",
    },
    nationality: {
        title: "Nationality",
    },
    idNumber: {
        title: "Passport / ID No",
    },
    idCountry: {
        title: "Passport / ID issuing country",
    },
    idAuthority: {
        title: "Passport / ID issuing authority",
    },
    idIssueDate: {
        title: "Passport / ID issue date",
    },
    idExpiryDate: {
        title: "Passport / ID expiry date",
    },
    declaredAddress: {
        title: "Declared address",
    },
    correspondenceAddress: {
        title: "Correspondence (residential physical) address",
    },
    phone: {
        title: "Telephone",
    },
    email: {
        title: "E-mail",
    },
    taxCountry: {
        title: "Tax Residence Country",
    },
    taxNumber: {
        title: "Taxpayer Identification Number",
    },
    noTIN: {
        title: "Country doesn't issue TIN",
    },
    isUSTaxpayer: {
        title: "Is Client a US Taxpayer?",
    },
    ltRelationship: {
        title: "Relationship with Lithuania (for non-residents only)",
    },
    fundsSource: {
        title: "Source of funds",
    },
    wealthSource: {
        title: "Source of wealth",
    },
    pepStatus: {
        title: "PEP Status",
    },
    isBeneficialOwner: {
        title: "Is customer the beneficial owner of funds in account?",
    },
    beneficialOwner_firstName: {
        title: "[Beneficial owner] First name",
    },
    beneficialOwner_lastName: {
        title: "[Beneficial owner] Last name",
    },
    beneficialOwner_middleName: {
        title: "[Beneficial owner] Middle name (if applicable)",
    },
    beneficialOwner_dateOfBirth: {
        title: "[Beneficial owner] Date of birth",
    },
    beneficialOwner_countryOfBirth: {
        title: "[Beneficial owner] Place of birth (country)",
    },
    beneficialOwner_citizenship: {
        title: "[Beneficial owner] Citizenship",
    },
    beneficialOwner_nationality: {
        title: "[Beneficial owner] Nationality",
    },
    beneficialOwner_idNumber: {
        title: "[Beneficial owner] Passport / ID No",
    },
    beneficialOwner_idCountry: {
        title: "[Beneficial owner] Passport / ID issuing country",
    },
    beneficialOwner_idAuthority: {
        title: "[Beneficial owner] Passport / ID issuing authority",
    },
    beneficialOwner_idIssueDate: {
        title: "[Beneficial owner] Passport / ID issue date",
    },
    beneficialOwner_idExpiryDate: {
        title: "[Beneficial owner] Passport / ID expiry date",
    },
    beneficialOwner_declaredAddress: {
        title: "[Beneficial owner] Declared address",
    },
    beneficialOwner_correspondenceAddress: {
        title: "[Beneficial owner] Correspondence (residential physical) address",
    },
    beneficialOwner_phone: {
        title: "[Beneficial owner] Telephone",
    },
    beneficialOwner_email: {
        title: "[Beneficial owner] E-mail",
    },
    beneficialOwner_taxCountry: {
        title: "[Beneficial owner] Tax Residence Country",
    },
    beneficialOwner_taxNumber: {
        title: "[Beneficial owner] Taxpayer Identification Number",
    },
    beneficialOwner_noTIN: {
        title: "[Beneficial owner] Country doesn't issue TIN",
    },
    beneficialOwner_isUSTaxpayer: {
        title: "[Beneficial owner] Is Client a US Taxpayer?",
    },
    cryptoTurnover: {
        title: "What is the expected monthly turnover in cryptocurrencies?",
    },
    fiatTurnover: {
        title: "What is the expected monthly turnover in FIAT?",
    },
    fiatIncomingQuantity: {
        title: "What is the expected quantity of incoming FIAT transactions?",
    },
    fiatOutcomingQuantity: {
        title: "What is the expected quantity of outcoming FIAT transactions?",
    },
    fundPlan: {
        title: "How do you plan to fund your account?",
    },
    accountPurpose: {
        title: "What is the purpose of opening the account?",
    },
    finInsName: {
        title: "Name of financial institution",
    },
    incorporationCountry: {
        title: "Country of incorporation",
    },
    wasInvolved: {
        title: "Have you ever been involved in any money laundering or terrorist financing activities?",
    },
    wasInvolvedExplanation: {
        title: "[Explanation] Have you ever been involved in any money laundering or terrorist financing activities?",
    },
    profession: {
        title: "Profession information",
    },
    wasConvicted: {
        title: "Have you ever been convicted of any financial crimes?",
    },
    wasConvictedExplanation: {
        title: "[Explanation] Have you ever been convicted of any financial crimes?",
    },
    wasDenied: {
        title: "Have you ever been denied an account by any financial institution?",
    },
    wasDeniedExplanation: {
        title: "[Explanation] Have you ever been denied an account by any financial institution?",
    },
    hasDebtsOrLoans: {
        title: "Do you have any outstanding debt or loan obligations?",
    },
    debtOrLoanAmount: {
        title: "Debts/Loans amount",
    },
}
