import React, {useCallback, useEffect, useRef, useState} from 'react';
import Container from "../../components/UI/Container/Container";
import classes from './Login.module.scss';
import {Button, Form, Input, Typography} from "antd";
import {GENERAL_SCHEME} from "../../schemas";
import {useDispatch} from "react-redux";
import {login} from "./actions";

const Login = () => {
	const [loading, setLoading] = useState(false);
	const firstFieldRef = useRef(null);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		firstFieldRef.current.focus({
			cursor: 'start',
		})
	}, []);

	const onFormSubmit = useCallback((formData) => {
		setLoading(true);
		dispatch(login(formData)).finally(() => setLoading(false));
	}, []);

	return (
		<div className={classes.wrapper}>
			<Container className={classes.container}>
				<div className={classes.formContainer}>
					<Typography.Title style={{color: 'white'}}>Login</Typography.Title>
					<Form validateTrigger="onBlur" className="w100" size="large" requiredMark="hidden"
					      disabled={loading} form={form} name="authForm" onFinish={onFormSubmit}>
						<Form.Item rules={GENERAL_SCHEME.required} name="login">
							<Input ref={firstFieldRef} placeholder="Login"/>
						</Form.Item>
						<Form.Item rules={GENERAL_SCHEME.required} name="password">
							<Input.Password placeholder="Password"/>
						</Form.Item>
						<Button type="primary" loading={loading} onClick={form.submit} block>Войти</Button>
					</Form>
				</div>
			</Container>
		</div>

	);
};

export default Login;
