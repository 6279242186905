import React, {useCallback, useEffect, useState} from 'react';
import Container from "../../components/UI/Container/Container";
import Block from "../../components/UI/Block/Block";
import {Button, Col, Divider, Empty, Flex, Form, Input, message, Row, Space, Switch, Table, Typography} from "antd";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import {API} from "../../http";
import moment from "moment";
import {TRANSACTION_TYPE} from "../../utils/constants";
import {formatNumberPrecision} from "../../utils/helpers";
import classes from "./Users.module.scss";
import {FIELDS_SCHEME, GENERAL_SCHEME} from "../../schemas";

const cleanObject = (obj) => {
  return Object.entries(obj)
    .filter(([_, v]) => v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

const Users = () => {
  const [searchUsersLoading, setSearchUsersLoading] = useState(false);
  const [loadingTransactionsID, setLoadingTransactionsID] = useState();
  const [blockingUserID, setBlockingUserID] = useState();
  const [users, setUsers] = useState([]);

  const [form] = Form.useForm();

  const handleUserBlock = useCallback(async (id, value) => {
    setBlockingUserID(id);
    try {
      await API.post(NEW_ENDPOINTS.ADMIN.USERS.BLOCK, {
        id,
        blocked: !value,
      });
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === id ? { ...user, blocked: !value } : user
        )
      );
    } catch (e) {
      message.error('Something went wrong');
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === id ? { ...user, blocked: value } : user
        )
      );
    } finally {
      setBlockingUserID(undefined);
    }
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 160,
      render: (value) => <Typography.Text style={{width: 150}} copyable>{value}</Typography.Text>
    },
    {
      title: 'First name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 150,
      render: (value) => <Typography.Text style={{width: 150}}>{value}</Typography.Text>
    },
    {
      title: 'Last name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 150,
      render: (value) => <Typography.Text style={{width: 150}}>{value}</Typography.Text>
    },
    {
      title: 'E-mail',
      dataIndex: 'email_address',
      key: 'email_address',
      width: 250,
      render: (value) => <Typography.Text style={{width: 250}} ellipsis={{ tooltip: value }} copyable>{value}</Typography.Text>
    },
    {
      title: 'Blocked',
      dataIndex: 'blocked',
      key: 'blocked',
      align: 'center',
      width: 150,
      render: (value, record) => <Switch defaultChecked={value} loading={blockingUserID === record.id} onChange={handleUserBlock.bind(this, record.id, value)} />
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (value) => <Typography.Text style={{ width: 150 }}
        ellipsis>{moment(value).format('DD.MM.YYYY, HH:mm:ss')}</Typography.Text>,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const dateA = moment(a.createdAt);
        const dateB = moment(b.createdAt);
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        filters: Object.values(TRANSACTION_TYPE).map(tType => ({
          text: tType,
          value: tType
        })),
        filterMode: 'menu',
        width: 120,
        align: 'center',
        onFilter: (value, innerRecord) => innerRecord.type.includes(value),
        render: (value) => <Typography.Text>{value}</Typography.Text>
      },
      {
        title: 'Amount',
        key: 'amount',
        width: 250,
        align: 'center',
        render: (innerRecord) => {
          const fiatAmount = formatNumberPrecision(innerRecord.amount_fiat, 2),
            cryptoAmount = formatNumberPrecision(innerRecord.amount_crypto, 4);
          let amountTableValue = '', feeTableValue = '';
          if ([TRANSACTION_TYPE.SEND_FIAT, TRANSACTION_TYPE.PAYMENT_IN].includes(innerRecord.type)) {
            amountTableValue = `${fiatAmount} EUR`;
            feeTableValue = `${innerRecord.fee_fiat} EUR`;
          } else {
            amountTableValue = `${cryptoAmount} USDT`;
            feeTableValue = `${innerRecord.fee_crypto} USDT`;
          }
          return (
            <Flex justify="center" align="center" vertical>
              <Typography.Text>{amountTableValue}</Typography.Text>
              <Typography.Text className={classes.amountMeta}>[fee: {feeTableValue}]</Typography.Text>
            </Flex>
          )
        }
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 180,
        render: (value) => <Typography.Text style={{ width: 150 }}
                                            ellipsis>{moment(value).format('DD.MM.YYYY, HH:mm:ss')}</Typography.Text>,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          const dateA = moment(a.date);
          const dateB = moment(b.date);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    ];

    const selectedUser = users.find(u => u.id === record.id);
    return <Table locale={{ emptyText: 'No transactions found' }} scroll={{ x: 550 }} loading={loadingTransactionsID === record.id} columns={columns} dataSource={selectedUser?.transactions || []} pagination={false} />;
  };

  const searchUsers = async (formData = {}) => {
    setSearchUsersLoading(true);
    try {
      const cleanedData = cleanObject(formData);
      const {data: { result }} = await API.post(NEW_ENDPOINTS.ADMIN.USERS.LIST, cleanedData);
      console.log({result})
      setUsers(result.map(u => ({ ...u, key: u.id })));
    } catch (e) {
      message.error('Something went wrong');
    } finally {
      setSearchUsersLoading(false);
    }
  }

  const loadUserTransactions = async (user_id) => {
    setLoadingTransactionsID(user_id);
    try {
      const {data: {transactions}} = await API.post(NEW_ENDPOINTS.ADMIN.USERS.USER_TRANSACTIONS, {
        user_id
      });
      setUsers(prevUsers => prevUsers.map(user =>
        user.id === user_id ? { ...user, transactions } : user
      ));
    } catch (error) {
      console.error('Error loading transactions:', error);
      message.error('Failed to load transactions');
      return [];
    } finally {
      setLoadingTransactionsID(undefined);
    }
  };

  const onExpand = async (expanded, record) => {
    if (expanded && !record.transactions) await loadUserTransactions(record.id);
  };

  const resetFields = () => {
    form.resetFields();
    setUsers([]);
  }

  // useEffect(() => {
  //   searchUsers()
  // }, []);

  return (
    <Container>
      <Block>
        <Space direction="vertical" className="w100" size="small">
          <Form size="large" labelCol={{ flex: '110px' }} labelAlign="left" labelWrap wrapperCol={{flex: 1}}
                layout="horizontal" className="w100" preserve={false} requiredMark="hidden" disabled={searchUsersLoading}
                form={form} name="commentForm" onFinish={searchUsers}>
            <Row gutter={24}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item rules={GENERAL_SCHEME.alphaNumeric} label="ID" name="id">
                  <Input placeholder="Enter ID" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item rules={FIELDS_SCHEME.email} label="E-mail" name="email">
                  <Input placeholder="Enter E-mail"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item rules={GENERAL_SCHEME.alphaSpace} label="First name" name="firstname">
                  <Input placeholder="Enter first name"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item rules={GENERAL_SCHEME.alphaSpace} label="Last name" name="lastname">
                  <Input placeholder="Enter last name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item rules={FIELDS_SCHEME.iban} label="IBAN" name="iban">
                  <Input placeholder="Enter IBAN" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item rules={FIELDS_SCHEME.wallet} label="Wallet address" name="wallet">
                  <Input placeholder="Enter wallet address" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Flex justify="flex-end">
            <Space>
              <Button onClick={resetFields}>Reset</Button>
              <Button onClick={form.submit} type="primary">Search</Button>
            </Space>
          </Flex>
        </Space>

        <Divider/>

        <Table locale={{
          emptyText: <Empty description={false} />,
        }} showSorterTooltip={false}  pagination={{position: ['none', 'bottomCenter'], hideOnSinglePage: true}}
               loading={searchUsersLoading} dataSource={users} scroll={{x: 1010}} columns={columns}
               expandable={{
                 expandedRowRender,
                 onExpand,
               }}/>
      </Block>
    </Container>
  );
};

export default Users;
