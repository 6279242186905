import React, {useState} from 'react';
import {DatePicker, Form, Modal} from "antd";
import {TRANSACTION_TYPE} from "../../utils/constants";
import dayjs from "dayjs";
import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import { saveAs } from 'file-saver';

const TransactionsExportModal = React.memo(({ open, onClose }) => {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const _onClose = () => {
        form.resetFields();
        onClose();
    }
    const onFinish = async ({range}) => {
        try {
            const {data} = await API.post(NEW_ENDPOINTS.ADMIN.TRANSACTIONS.EXPORT_CSV, {
                start: range[0].format('YYYY-MM-DD HH:mm:ss.SSS') + ' +00:00',
                end: range[1].format('YYYY-MM-DD HH:mm:ss.SSS') + ' +00:00'
            });
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, `${range[0].format('YYYY-MM-DD')} - ${range[1].format('YYYY-MM-DD')}.csv`);
            _onClose();
        } catch (e) {
            console.log({e});
        }
    }

    return (
        <Modal title="Export" open={open} okText="Export to .csv" onOk={form.submit} onCancel={_onClose}>
            <Form className="w100" size="large" preserve={false} requiredMark="hidden" disabled={loading}
                  form={form} name="updateForm" onFinish={onFinish}>
                <Form.Item name="range">
                    <DatePicker.RangePicker
                        presets={[
                            {
                                label: 'Today',
                                value: () => [dayjs().startOf('day'), dayjs()],
                            },
                            {
                                label: 'Last 7 Days',
                                value: [dayjs().add(-7, 'd').startOf('day'), dayjs()],
                            },
                            {
                                label: 'Last 14 Days',
                                value: [dayjs().add(-14, 'd').startOf('day'), dayjs()],
                            },
                            {
                                label: 'Last 30 Days',
                                value: [dayjs().add(-30, 'd').startOf('day'), dayjs()],
                            },
                            {
                                label: 'Last 90 Days',
                                value: [dayjs().add(-90, 'd').startOf('day'), dayjs()],
                            },
                        ]}
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default TransactionsExportModal;
