import {useLocation} from "react-router-dom";
import {findRouteKeyByPath} from "../utils/helpers";

export const useEnhancedLocation = () => {
    const location = useLocation();
    const routeKey = findRouteKeyByPath(location.pathname);

    return {
        ...location,
        routeKey,
    };
};
