import {TRANSACTION_STATUS, TRANSACTION_TYPE} from "./constants";
import {Tag} from "antd";
import {matchPath} from "react-router-dom";
import {ROUTES} from "../components/AppRouter/AppRouter";

export const formatNumberPrecision = (number, precision = 4) => {
	const stringNumber = String(number);
	const decimalIndex = stringNumber.indexOf('.') + 1;

	if (decimalIndex === 0) {
		// Число не содержит десятичную часть, возвращаем исходное число
		return number;
	}

	return Number(stringNumber.substring(0, decimalIndex + precision));
}

export const combineClasses = (...classNames) => {
	return classNames.filter(Boolean).join(' ');
}

export const findRouteKeyByPath = (pathname) => {
	for (const route of Object.values(ROUTES)) {
		const match = matchPath({ path: route.path }, pathname);
		if (match) {
			return route.key;
		}
	}
	return null;
};

