import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Button, Flex, Form, Input, message, Modal, Space, Spin, Tag, Typography, Watermark} from "antd";
import {fetchUserQuestionnaire, linkComment, removeComment, updateAMLStatus} from "../../actions";
import {questions as questionsData} from "../../data";
import classes from './QuestionnaireView.module.scss';
import {combineClasses} from "../../../../utils/helpers";
import {FilePdfOutlined, MinusOutlined} from "@ant-design/icons";
import {AML_STATUS, ROLES} from "../../../../utils/constants";
import {renderAMLStatus} from "../QuestionnairesTable/QuestionnairesTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {FaPlus} from "react-icons/fa";
import {GENERAL_SCHEME} from "../../../../schemas";
import { RxCrossCircled } from "react-icons/rx";

const QuestionnaireView = ({activeQuestionnaire, role, setQuestionnaires, open, onClose, ...props}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [commentQuestionID, setCommentQuestionID] = useState('');
  const [questions, setQuestions] = useState([]);

  const [form] = Form.useForm();

  const _onClose = () => {
    setQuestions([]);
    onClose();
  }

  const isAvailableToEdit = useMemo(() => {
    return [ROLES.ADMIN, ROLES.AML].includes(role);
  }, [role]);

  const exportPDF = useCallback(() => {
    const input = document.getElementById('divToPrint');
    const inputWidth = input.offsetWidth;
    const inputHeight = input.offsetHeight;

    setUpdateLoading(true);
    html2canvas(input, {
      scale: 2,
      useCORS: true,
    }).then(canvas => {
      const imgWidth = 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const pageHeight = 295;
      let heightLeft = imgHeight;

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${activeQuestionnaire.id}.pdf`);
    }).finally(() => setUpdateLoading(false));
  }, [activeQuestionnaire]);

  const fetchQuestionnaire = useCallback(() => {
    setDataLoading(true);
    fetchUserQuestionnaire(activeQuestionnaire.id)
      .then((response) => {
        setQuestions(response || []);
      })
      .finally(() => {
        setDataLoading(false)
      });
  }, [activeQuestionnaire]);

  const onResponseToQuestionnaire = useCallback((aml_status) => {
    setUpdateLoading(true);
    updateAMLStatus(activeQuestionnaire.id, aml_status)
      .then(() => {
        setQuestionnaires(prevQuestionnaires =>
          prevQuestionnaires.map(item =>
            item.id === activeQuestionnaire.id ? {...item, aml_status: aml_status} : item
          )
        );
        message.success(`AML status for id ${activeQuestionnaire.id} was successfully updated`);
        _onClose();
      })
      .catch((error) => {
        message.error(`Failed to update status for id ${activeQuestionnaire.id}:`);
        console.error(`Failed to update status for id ${activeQuestionnaire.id}:`, error);
      })
      .finally(() => {
        setUpdateLoading(false);
      })
  }, [activeQuestionnaire.id, onClose]);

  const onComment = useCallback(({comment, author, question_id}) => {
    setUpdateLoading(true);
    linkComment({user_id: activeQuestionnaire.id, question_id, author, comment})
      .then((commentObject) => {
        const updatedQuestions = questions.map(q => {
          if (q.id === question_id) {
            return {
              ...q,
              comment: commentObject,
            };
          }
          return q;
        });

        setQuestions(updatedQuestions);
        setCommentQuestionID('');
      })
      .finally(() => {
        setUpdateLoading(false);
      })
  }, [activeQuestionnaire.id, questions]);

  const onDeleteComment = useCallback((question_id) => {
    setUpdateLoading(true);
    removeComment({user_id: activeQuestionnaire.id, question_id})
      .then(() => {
        const updatedQuestions = questions.map(q => {
          if (q.id === question_id) {
            const { comment, ...restOfQuestion } = q;
            return restOfQuestion;
          }
          return q;
        });

        setQuestions(updatedQuestions);
        setCommentQuestionID('');
      })
      .finally(() => {
        setUpdateLoading(false);
      })
  }, [activeQuestionnaire.id, questions]);

  const getAnswerByQuestion = useCallback((question) => {
    const item = questions.find(item => item.question === question);
    return item ? item : {};
  }, [questions]);

  useEffect(() => fetchQuestionnaire(), []);

  return (
    <Modal style={{top: 10}} width={800} title={
      <Space align="center">
        <Typography.Text>
          {activeQuestionnaire.id}
        </Typography.Text>
        <Typography.Text>—</Typography.Text>
        {renderAMLStatus(activeQuestionnaire.aml_status)}
      </Space>
    } open={open} onCancel={_onClose} footer={!dataLoading && isAvailableToEdit && [
      <Space direction="vertical" size="small" className="w100">
        <Flex style={{marginBottom: 10}} className="w100">
          <Button size="large" disabled={updateLoading || activeQuestionnaire.aml_status === AML_STATUS.REJECTED || !isAvailableToEdit}
                  onClick={() => onResponseToQuestionnaire(AML_STATUS.REJECTED)} type="default" danger
                  block>Reject</Button>
          <Button size="large" disabled={updateLoading || activeQuestionnaire.aml_status === AML_STATUS.APPROVED || !isAvailableToEdit}
                  onClick={() => onResponseToQuestionnaire(AML_STATUS.APPROVED)} type="default" block>Approve</Button>
        </Flex>
        <Button disabled={updateLoading} type="dashed" icon={<FilePdfOutlined/>} onClick={exportPDF} block>Export to
          PDF</Button>
      </Space>
    ]} {...props}>
      {dataLoading ? (
        <Flex justify="center" align="center" style={{height: 300}}>
          <Spin/>
        </Flex>
      ) : (
        <Watermark zIndex={1} content="KRYPTOLITE">
          <div style={{
            fontFamily: 'Times New Roman, Times, serif',
            padding: '24px 0',
            lineHeight: '1.6',
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #ddd',
            boxShadow: '0 2px 2px rgba(0, 0, 0, 0.05)'
          }} id="divToPrint">
            <Typography.Title level={3} style={{textAlign: 'center'}}>Questionnaire Answers</Typography.Title>
            {Object.values(questionsData).map(question => {
              const answer = getAnswerByQuestion(question.title);
              if (answer?.id) {
                const is500Score = answer.score === 500, hasComment = answer.comment ? Object.keys(answer.comment).length > 0 : false;
                return (
                  <Flex key={answer.id} align="baseline" gap={10}
                        className={combineClasses(classes.questionnaireRow, is500Score && classes.score500)}>
                    <div className={classes.qRowMarker}>
                      <MinusOutlined/>
                    </div>
                    <Flex className={classes.qContent} vertical>
                      <Typography.Text style={{lineHeight: 2}}>
                        <Typography.Text strong>{question.title}: </Typography.Text>
                        {answer.answer ?? '-/-'}
                        <Badge offset={[5, 0]} overflowCount={500} size="small" count={answer.score}/>
                      </Typography.Text>

                      {answer.comment && (
                        <Flex align="center" gap={5} className={classes.qCommentRow}>
                          <div className={classes.qCommentContainer}>
                            <Typography.Paragraph type="secondary" className={classes.qCommentText}>
                              <Typography.Text className={classes.qCommentAuthor} strong>{answer.comment.author.toUpperCase()}: </Typography.Text>
                              {answer.comment.comment}
                            </Typography.Paragraph>
                          </div>
                          <div onClick={() => onDeleteComment(answer.id)} className={classes.qCommentDeleteBtn}>
                            <RxCrossCircled/>
                          </div>
                        </Flex>
                      )}
                    </Flex>

                    {!hasComment && isAvailableToEdit && (
                      <div onClick={() => setCommentQuestionID(answer.id)} className={classes.qCommentBtn}>
                        <FaPlus size={12} style={{color: '#fff'}}/>
                      </div>
                    )}
                  </Flex>
                )
              }
            })}
          </div>
        </Watermark>
      )}

      <Modal
        title="Add Comment"
        okText="Submit Comment"
        open={Boolean(commentQuestionID)}
        onOk={form.submit}
        onCancel={() => setCommentQuestionID('')}
        destroyOnClose
      >
        <Form className="w100" preserve={false} requiredMark="hidden" disabled={updateLoading}
              form={form} name="commentForm" onFinish={onComment}>
          <Form.Item initialValue={commentQuestionID} rules={GENERAL_SCHEME.required} name="question_id" hidden>
            <Input disabled/>
          </Form.Item>
          <Form.Item rules={GENERAL_SCHEME.required} name="author">
            <Input
              maxLength={100}
              placeholder="Author"
            />
          </Form.Item>
          <Form.Item rules={GENERAL_SCHEME.required} name="comment">
            <Input.TextArea
              maxLength={100}
              placeholder="Type your comment here..."
              autoSize={{
                minRows: 4,
                maxRows: 4,
              }}
              showCount
            />
          </Form.Item>
        </Form>

      </Modal>
    </Modal>
  );
};

export default QuestionnaireView;
