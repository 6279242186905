export const NEW_ENDPOINTS = Object.freeze({
	AUTH: {
		LOGIN: '/admin/login',
	},
	ADMIN: {
		INFO: '/admin/setting',
		UPDATE: '/admin/upd_setting',
		COUNTRIES: '/admin/countries',
		TRANSACTIONS: {
			LIST: '/admin/transaction',
			EXPORT_CSV: '/v3/even/transactions',
		},
		USERS: {
			LIST: '/admin/users',
			USER_TRANSACTIONS: '/admin/user_transactions',
			BLOCK: '/admin/user_blocked',
		},
		AML: {
			AML_USERS: '/admin/aml_users',
			AML_USER: '/admin/aml_user',
			UPDATE_AML_STATUS: '/admin/update_aml_status',
			COMMENT: '/admin/aml_question_comment'
		}
	},
});
