import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classes from "./Dashboard.module.scss";
import Container from "../../components/UI/Container/Container";
import {Button, Col, Divider, Form, Input, Row, Segmented, Space, Statistic, Typography} from "antd";
import {fetchTradeInfo, updateTradeInfo} from "./actions";
import Block from "../../components/UI/Block/Block";
import {CURRENCY, ROLES, TRANSACTION_TYPE} from "../../utils/constants";
import TransactionsTable from "../../components/TransactionsTable/TransactionsTable";
import {connect, useDispatch} from "react-redux";
import {logout} from "../../store/actions/app";
import {getUser, isAdmin} from "../../store/selectors";

const Dashboard = ({user}) => {
    const [loading, setLoading] = useState(false);
    const [tradeInfo, setTradeInfo] = useState({});

    const [form] = Form.useForm();
    const currentDirection = Form.useWatch('type', form);

    const isAdmin = useMemo(() => {
        return user.role === ROLES.ADMIN;
    }, [user]);

    useEffect(() => {
        if (currentDirection) {
            setLoading(true);
            fetchTradeInfo(currentDirection, currentDirection === TRANSACTION_TYPE.SEND_FIAT ? CURRENCY.EUR : CURRENCY.USDT).then((response) => {
                setTradeInfo(response);
                form.setFieldsValue({fee: response?.fee, min_sum: response?.min_sum, max_sum: response?.max_sum});
            }).finally(() => setLoading(false));
        }
    }, [currentDirection])

    const onFormSubmit = useCallback((formData) => {
        setLoading(true);
        updateTradeInfo(formData).then((response) => {
            setTradeInfo(response);
            form.setFieldsValue({fee: response.fee, min_sum: response.min_sum, max_sum: response.max_sum});
        }).finally(() => setLoading(false));
    }, []);

    return (
        <Container className={classes.container}>
            <Row gutter={[40, 20]} wrap>
                <Col xs={24} md={8} lg={6}>
                    <Block>
                        <Form labelCol={{span: 8}} labelAlign="left" initialValues={{type: TRANSACTION_TYPE.BUY}}
                              validateTrigger="onChange" className="w100" size="large" requiredMark="hidden"
                              disabled={loading} form={form} name="updateForm" onFinish={onFormSubmit}>
                            <Form.Item name="type">
                                <Segmented options={[
                                    {value: TRANSACTION_TYPE.BUY, label: TRANSACTION_TYPE.BUY},
                                    {value: TRANSACTION_TYPE.SELL, label: TRANSACTION_TYPE.SELL},
                                    {value: TRANSACTION_TYPE.SEND, label: TRANSACTION_TYPE.SEND},
                                    {value: TRANSACTION_TYPE.SEND_FIAT, label: TRANSACTION_TYPE.SEND_FIAT},
                                ]} block/>
                            </Form.Item>
                            <Form.Item label="Fee" name="fee">
                                <Input disabled={!isAdmin} suffix="%" placeholder="Fee"/>
                            </Form.Item>
                            <Form.Item label="Min. sum" name="min_sum">
                                <Input disabled={!isAdmin}
                                       suffix={currentDirection === TRANSACTION_TYPE.SEND_FIAT ? CURRENCY.EUR : CURRENCY.USDT}
                                    placeholder="Min. sum"/>
                            </Form.Item>
                            <Form.Item label="Max. sum" name="max_sum">
                                <Input
                                    suffix={currentDirection === TRANSACTION_TYPE.SEND_FIAT ? CURRENCY.EUR : CURRENCY.USDT}
                                    disabled={!isAdmin} placeholder="Max. sum"/>
                            </Form.Item>

                            <Button type="primary" disabled={!isAdmin} loading={loading} onClick={form.submit} block>Update</Button>
                        </Form>
                    </Block>
                    <Row justify="center">
                        <Button type="default" onClick={logout}>Log out</Button>
                    </Row>
                </Col>
                <Col xs={24} md={16} lg={18}>
                    <Block>
                        <Space size="large" direction="vertical">
                            <Space direction="vertical">
                                <Typography.Text type="secondary">EUR Wallet: <Typography.Text
                                    copyable={tradeInfo?.walletEUR}>{tradeInfo?.walletEUR}</Typography.Text></Typography.Text>
                                <Typography.Text type="secondary">USDT Wallet: <Typography.Text
                                    copyable={tradeInfo?.walletUSDT}>{tradeInfo?.walletUSDT}</Typography.Text></Typography.Text>
                            </Space>
                            <Space size="large" wrap>
                                <Statistic loading={loading} title="Balance EUR" value={+tradeInfo?.balance_eur}
                                           precision={2}/>
                                <Statistic loading={loading} title="Balance TRON" value={+tradeInfo?.balance_trx}
                                           precision={6}/>
                                <Statistic loading={loading} title="Balance USDT" value={+tradeInfo?.balance_usdt}
                                           precision={6}/>
                            </Space>
                        </Space>
                        <Divider/>
                        <TransactionsTable/>
                    </Block>
                </Col>
            </Row>
        </Container>
    );
};
export const mapStateToProps = state => ({
    user: getUser(state),
});
export default connect(mapStateToProps)(Dashboard);
