import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Flex, Input, Row, Space, Table, Tag, Tooltip, Typography} from "antd";
import {TRANSACTION_STATUS, TRANSACTION_TYPE} from "../../utils/constants";
import {formatNumberPrecision} from "../../utils/helpers";
import classes from "../../pages/Dashboard/Dashboard.module.scss";
import moment from "moment/moment";
import {ExportOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {fetchTransactions} from "../../pages/Dashboard/actions";
import SearchInput from "../UI/SearchInput/SearchInput";
import {useColumnSearchProps} from "../../hooks/useColumnSearchProps";
import TransactionsExportModal from "../TransactionsExportModal/TransactionsExportModal";

function renderStatus(status) {
    let color = '#009be2';
    if ([TRANSACTION_STATUS.ERROR_DONE, TRANSACTION_STATUS.CANCEL].includes(status)) color = '#f5222d';
    else if (status === TRANSACTION_STATUS.DONE) color = '#52c41a';
    return <Tag color={color}>{status}</Tag>;
}

const TransactionsTable = () => {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [globalSearchValue, setGlobalSearchValue] = useState('');

    const {
        getColumnSearchProps,
        searchText,
        searchedColumn,
    } = useColumnSearchProps();

    const loadTransactions = useCallback(() => {
        setLoading(true);
        fetchTransactions().then(setTransactions).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        loadTransactions();
    }, []);

    const columns = [
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            width: 150,
            ...getColumnSearchProps('ip', (value) => <Typography.Text copyable={value}>{value}</Typography.Text>),
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 350,
            ...getColumnSearchProps('id', (value) => <Typography.Text copyable={value}>{value}</Typography.Text>),
            // render: (value) => <Typography.Text copyable>{value}</Typography.Text>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            filters: Object.values(TRANSACTION_TYPE).map(tType => ({
                text: tType,
                value: tType
            })),
            filterMode: 'menu',
            width: 120,
            align: 'center',
            onFilter: (value, record) => record.type.includes(value),
            render: (value) => <Typography.Text>{value}</Typography.Text>
        },
        {
            title: 'Amount',
            key: 'amount',
            width: 250,
            align: 'center',
            render: (record) => {
                const fiatAmount = formatNumberPrecision(record.sum_fiat, 2),
                    cryptoAmount = formatNumberPrecision(record.sum_crypto, 4);
                let amountTableValue = '';
                if (record.type === TRANSACTION_TYPE.SEND_FIAT) amountTableValue = `${fiatAmount} EUR`;
                else if (record.sum_fiat) amountTableValue = `${cryptoAmount} ${record.crypto} (${fiatAmount} EUR)`;
                else amountTableValue = `${cryptoAmount} ${record.crypto}`;

                return (
                    <Flex justify="center" align="center" vertical>
                        <Typography.Text>{amountTableValue}</Typography.Text>
                        <Typography.Text
                            className={classes.amountMeta}>{record.course} / {record.fee}%</Typography.Text>
                    </Flex>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            align: 'center',
            render: (value) => renderStatus(value)
        },
        {
            title: 'trc_id',
            dataIndex: 'trc_id',
            key: 'trc_id',
            width: 150,
            render: (value, record) => record.status === TRANSACTION_STATUS.ERROR_DONE ? '' : <Typography.Text style={{width: 150}} ellipsis={{tooltip: value}}
                                                                                                               copyable={value}>{value}</Typography.Text>
        },
        {
            title: 'trx_id',
            dataIndex: 'trx_id',
            key: 'trx_id',
            width: 150,
            render: (value) => <Typography.Text style={{width: 150}} ellipsis={{tooltip: value}}
                                                copyable={value}>{value}</Typography.Text>
        },
        {
            title: 'fiat_payment_id',
            dataIndex: 'fiat_payment_id',
            key: 'fiat_payment_id',
            width: 150,
            render: (value) => <Typography.Text style={{width: 150}}
                                                ellipsis={{tooltip: value}}>{value}</Typography.Text>
        },
        {
            title: 'Updated',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            fixed: 'right',
            width: 180,
            render: (value) => <Typography.Text
                ellipsis>{moment(value).format('DD.MM.YYYY, HH:mm:ss')}</Typography.Text>,
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                const dateA = moment(a.updatedAt).toDate();
                const dateB = moment(b.updatedAt).toDate();
                if (dateA < dateB) {
                    return -1;
                } else if (dateA > dateB) {
                    return 1;
                } else {
                    return 0;
                }
            },
        },
    ];
    const filteredTransactions = useMemo(() => {
        const fieldsToCompare = ['ip', 'id', 'trc_id', 'trx_id', 'fiat_payment_id'];
        if (globalSearchValue) {
            return transactions.filter(item => {
                for (const field of fieldsToCompare) {
                    if (item[field] && item[field].toString().toLowerCase().includes(globalSearchValue.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            });
        } else return transactions;
    }, [globalSearchValue, transactions]);

    return (
        <React.Fragment>
            <Space direction="vertical">
                <Flex justify="space-between" gap={30} className="w100" align="baseline">
                    <Flex className="w100" vertical>
                        <SearchInput placeholder="Global search"
                                     value={globalSearchValue}
                                     onChange={(e) => setGlobalSearchValue(e.target.value)}/>
                        {globalSearchValue && (
                            <Typography.Text style={{ fontSize: 12 }} type="secondary">{filteredTransactions.length} transactions found</Typography.Text>
                        )}
                    </Flex>
                    <Space>
                        <Tooltip title="Export">
                            <Button icon={<ExportOutlined />} onClick={() => setExportModalVisible(true)} shape="round" type="primary"/>
                        </Tooltip>
                        <Tooltip title="Reload">
                            <Button icon={<ReloadOutlined />} onClick={loadTransactions} shape="round" type="primary"/>
                        </Tooltip>
                    </Space>
                </Flex>
                <Table locale={{
                    emptyText: 'No transactions found',
                }} showSorterTooltip={false}
                       pagination={{position: ['none', 'bottomCenter'], hideOnSinglePage: true}}
                       loading={loading} dataSource={filteredTransactions} scroll={{x: 1620}} columns={columns}/>
            </Space>

            <TransactionsExportModal open={exportModalVisible} onClose={() => setExportModalVisible(false)}/>
        </React.Fragment>
    );
};

export default TransactionsTable;
