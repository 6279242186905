import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import {message} from "antd";
import {CURRENCY, TRANSACTION_TYPE} from "../../utils/constants";

export const fetchTransactions = async () => {
	try {
		const {data} = await API.get(NEW_ENDPOINTS.ADMIN.TRANSACTIONS.LIST);
		return data;
	} catch (e) {
		message.error('Error getting transactions');
		throw e;
	}
}
export const fetchTradeInfo = async (type, currency = CURRENCY.USDT) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.ADMIN.INFO, {type, currency});
		return data;
	} catch (e) {
		message.error('Error getting trade data');
		throw e;
	}
}
export const updateTradeInfo = async (formData) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.ADMIN.UPDATE, {
			type: formData.type,
			currency: formData.type === TRANSACTION_TYPE.SEND_FIAT ? "EUR" : "USDT",
			min_sum: formData.min_sum,
			max_sum: formData.max_sum,
			fee: formData.fee,
		});
		message.success('Data was successfully updated!')
		return data;
	} catch (e) {
		message.error('Error updating data');
		throw e;
	}
}
