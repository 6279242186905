import React from 'react';
import classes from "./Block.module.scss";
import {combineClasses} from "../../../utils/helpers";

const Block = ({children, className, ...props}) => {
	return (
		<div className={combineClasses(classes.container, className)} {...props}>
			{children}
		</div>
	);
};

export default Block;
