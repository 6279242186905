import React from 'react';
import {ConfigProvider, Input} from "antd";

const SearchInput = (props) => {
    return (
        <ConfigProvider theme={{
            components: {
                Input: {
                    colorBgContainer: "rgb(245, 245, 245)",
                    colorBgContainerDisabled: "rgba(0, 0, 0, 0.05)",
                    colorBorder: "rgb(245, 245, 245)",
                    colorPrimaryHover: "rgb(245, 245, 245)",
                    colorPrimaryActive: "rgb(245, 245, 245)",
                    controlOutline: "rgba(5, 145, 255, 0)",
                    colorPrimary: "rgb(245, 245, 245)",
                    paddingBlock: 8,
                    borderRadius: 12,
                }
            }
        }}>
            <Input placeholder="Search" allowClear {...props}/>
        </ConfigProvider>
    );
};

export default SearchInput;
