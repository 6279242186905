export const validateMessages = {
	required: "Required field",
	type: 'Field has an incorrect data type',
};

export const GENERAL_SCHEME = {
	required: [
		{
			required: true,
			message: "Required field",
		},
	],
	alpha: [
		{
			pattern: /^[a-zA-Z]+$/,
			message: 'Must contain only letters'
		},
	],
	alphaSpace: [
		{
			pattern: /^[a-zA-Z\s]+$/,
			message: 'Must contain only letters and spaces'
		},
	],
	alphaNumeric: [
		{
			pattern: /^[a-zA-Z0-9]+$/,
			message: 'Must contain only letters and numbers'
		},
	]
}

export const FIELDS_SCHEME = {
	name: [{
		min: 2,
	}, {
		max: 20,
	}, ...GENERAL_SCHEME.alpha],
	// dateOfBirth: [{
	// 	required: true,
	// }],
	email: [{
		type: 'email',
	}, {
		pattern: /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,63}|[0-9]{1,3})(\]?)$/,
		message: 'Email is not correct'
	}],
	address: [{
		type: 'string',
	}, {
		min: 2,
		message: 'The value is too short',
	}, {
		max: 30,
		message: 'Exceeds the allowed character length',
	}, {
		pattern: /^[a-zA-Z0-9\s\-,().&/\\]+$/,
		message: 'Address is not correct',
	}],
	phoneNumber: [{
		type: 'string',
	}, {
		min: 2,
		message: 'The value is too short',
	}, {
		max: 15,
		message: 'Exceeds the allowed character length',
	}],
	password: [{
		type: 'string',
	}, {
		min: 8,
		message: 'The value is too short',
	}, {
		max: 20,
		message: 'Exceeds the allowed character length',
	}, {
		pattern: /^(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/,
		message: 'The password must be at least 8 characters long and must contain at least one uppercase letter, one lowercase letter, and one number.'
	}],
	wallet: [{
		type: 'string',
	}, {
		pattern: /^[A-Za-z0-9]{30,40}$/,
		message: 'Invalid wallet format'
	}],
	iban: [
		{type: 'string'},
		{max: 34, message: 'Exceeds the allowed character length'},
		{pattern: /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/, message: 'Invalid iban format'}
	]
}
