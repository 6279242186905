import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import {message} from "antd";

export const fetchQuestionnairesList = async () => {
    try {
        const {data} = await API.post(NEW_ENDPOINTS.ADMIN.AML.AML_USERS);
        return data;
    } catch (e) {
        message.error('Error getting questionnaires');
        throw e;
    }
}

export const updateAMLStatus = async (id, aml_status) => {
    try {
        const {data} = await API.post(NEW_ENDPOINTS.ADMIN.AML.UPDATE_AML_STATUS, {
            id,
            aml_status
        });
        return data;
    } catch (e) {
        message.error('Something went wrong...');
        throw e;
    }
}
export const fetchUserQuestionnaire = async (id) => {
    try {
        const {data: { data }} = await API.post(NEW_ENDPOINTS.ADMIN.AML.AML_USER, {
            id,
        });
        return data;
    } catch (e) {
        message.error('Error getting questionnaire');
        throw e;
    }
}

export const linkComment = async ({user_id, question_id, comment, author}) => {
    try {
        await API.post(NEW_ENDPOINTS.ADMIN.AML.COMMENT, {
            user_id,
            question_id,
            comment,
            author,
        });
        const timestamp = new Date().toISOString();
        return {user_id, question_id, comment, author, createdAt: timestamp, updatedAt: timestamp};
    } catch (e) {
        message.error('Error sending a comment');
        throw e;
    }
}
export const removeComment = async ({user_id, question_id}) => {
    try {
        await API.delete(NEW_ENDPOINTS.ADMIN.AML.COMMENT, {
            user_id,
            question_id,
        });
    } catch (e) {
        message.error('Error deleting a comment');
        throw e;
    }
}
