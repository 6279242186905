import {PURGE} from "redux-persist/es/constants";

const initialState = {
	authKey: null,
	role: '',
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'User/SET': {
			// if (action.payload.meta) action.payload.meta = { ...state.meta, ...action.payload.meta };
			return {...state, ...action.payload};
		}

		case PURGE: {
			return initialState;
		}

		default:
			return state;
	}
};

export default userReducer;
