import {PURGE} from "redux-persist/es/constants";

const initialState = {

}

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case PURGE: {
			return initialState;
		}

		default:
			return state;
	}
};
export default appReducer;
