import React, {useEffect, useState} from 'react';
import Block from "../../components/UI/Block/Block";
import Container from "../../components/UI/Container/Container";
import {Flex, message, Spin, Transfer, Typography} from "antd";
import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";

const RegCountries = () => {
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCounties] = useState([]);
  const [restrictedCountries, setRestrictedCountries] = useState([]);
  const fetchCountries = async () => {
    setCountriesLoading(true);
    try {
      const {data} = await API.get(NEW_ENDPOINTS.ADMIN.COUNTRIES),
        dataObject = data.map(c => ({ key: c.abbr, title: c.name, restricted: c.restricted }));
      setCounties(dataObject);
      setRestrictedCountries(dataObject.filter(c => c.restricted).map(c => c.key));
    } catch (e) {
      setCounties([]);
      setRestrictedCountries([]);
    } finally {
      setCountriesLoading(false);
    }
  }
  const updateCountries = async (countriesArray) => {
    setLoading(true);
    try {
     await API.post(NEW_ENDPOINTS.ADMIN.COUNTRIES, {
        countries: countriesArray,
      });
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    const countriesToMove = countries.filter(c => moveKeys.includes(c.key));
    setLoading(true);
    updateCountries(countriesToMove.map(c => ({abbr: c.key, restricted: direction === 'right'})))
      .then(() => setRestrictedCountries(nextTargetKeys))
      .catch(() => message.error('Something went wrong'))
      .finally(() => setLoading(false));
  };
  const filterOption = (inputValue, option) => {
    return `${option.key} ${option.title}`.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <Container>
      <Spin tip="Fetching countries list" spinning={countriesLoading}>
        <Block>
          <Typography.Title level={3}>Countries Available for Registration</Typography.Title>
          <Typography.Paragraph type="secondary">
            This interface allows you to manage which countries are allowed to register for our services. On the left is a list of currently available countries where users can sign up. To restrict a country, select it and click 'Restrict', moving it to the right-side list of restricted countries. To allow registration again, select the country from the right list and click 'Unrestrict', which will move it back to the available list. Changes are applied immediately.
          </Typography.Paragraph>
          <Flex justify="center" align="center">
            <Transfer
              dataSource={countries}
              titles={['Allowed countries', 'Restricted countries']}
              operations={['Restrict', 'Unrestrict']}
              listStyle={{
                width: 300,
                height: 400,
              }}
              filterOption={filterOption}
              targetKeys={restrictedCountries}
              onChange={onChange}
              disabled={loading || countriesLoading}
              render={(item) => `[${item.key}] ${item.title}`}
              showSearch
            />
          </Flex>
        </Block>
      </Spin>
    </Container>
  );
};

export default RegCountries;
