import React, {useEffect, useMemo, useState} from 'react';
import classes from "../Dashboard/Dashboard.module.scss";
import Container from "../../components/UI/Container/Container";
import QuestionnairesTable from "./components/QuestionnairesTable/QuestionnairesTable";
import Block from "../../components/UI/Block/Block";
import {fetchQuestionnairesList} from "./actions";
import {Divider, Space, Statistic, Typography} from "antd";
import {AML_STATUS} from "../../utils/constants";
import {useColumnSearchProps} from "../../hooks/useColumnSearchProps";
import QuestionnaireView from "./components/QuestionnaireView/QuestionnaireView";
import {getUser, getUserRole} from "../../store/selectors";
import {connect} from "react-redux";

const QuestionnairesList = ({role}) => {
    const [loading, setLoading] = useState(false);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [activeQuestionnaire, setActiveQuestionnaire] = useState({});

    const fetchQuestionnaires = () => {
        setLoading(true);
        fetchQuestionnairesList().then(response => setQuestionnaires(response.map(item => ({...item, key: item.id})))).finally(() => setLoading(false));
    }
    const pendingReviewQuestionnaires = useMemo(() => questionnaires.filter(i => i.aml_status && i.aml_status === AML_STATUS.MANUAL), [questionnaires]);
    const completedQuestionnaires = useMemo(() => questionnaires.filter(i => i.aml_status && i.aml_status !== AML_STATUS.NOT_CHECKED), [questionnaires]);

    const onActionView = (questionnaireObject) => setActiveQuestionnaire(questionnaireObject);

    useEffect(() => {
        fetchQuestionnaires();
    }, []);

    return (
        <Container>
            <Block>
                <Space size="large" direction="vertical">
                    <Space size="large" wrap>
                        <Statistic loading={loading} title="Questionnaires received" value={completedQuestionnaires.length ?? '-/-'}
                                   precision={0}/>
                        <Statistic loading={loading} title="Pending review" value={pendingReviewQuestionnaires.length ?? '-/-'}
                                   precision={0}/>
                    </Space>
                </Space>
                <Divider/>
                <QuestionnairesTable onActionView={onActionView} role={role} fetchQuestionnaires={fetchQuestionnaires} dataLoading={loading} questionnaires={questionnaires}
                                     setQuestionnaires={setQuestionnaires}/>
            </Block>
            {activeQuestionnaire.id && <QuestionnaireView setQuestionnaires={setQuestionnaires}
                                                          role={role} open={activeQuestionnaire.id} onClose={() => setActiveQuestionnaire({})}
                                                          activeQuestionnaire={activeQuestionnaire}/>}
        </Container>
    );
};
const mapStateToProps = state => ({
    role: getUserRole(state),
});
export default connect(mapStateToProps)(QuestionnairesList);
