import React, {useCallback, useMemo} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {getUser} from "../../store/selectors";
import {connect} from "react-redux";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Login from "../../pages/Login/Login";
import {API} from "../../http";
import QuestionnairesList from "../../pages/QuestionnairesList/QuestionnairesList";
import RegCountries from "../../pages/RegCountries/RegCountries";
import Users from "../../pages/Users/Users";
import {ROLES} from "../../utils/constants";

export const ROUTES = Object.freeze({
	DASHBOARD: {
		key: 'dashboard',
		path: '/admin/dashboard',
	},
	SIGN_IN: {
		key: 'login',
		path: '/auth/login',
	},
	QUESTIONNAIRES: {
		key: 'questionnaires',
		path: '/admin/questionnaires',
	},
	COUNTRIES: {
		key: 'countries',
		path: '/admin/users/countries',
	},
	USERS: {
		key: 'users',
		path: '/admin/users/list',
	}
});
const authorizedRoutes = Object.freeze([
	{route: ROUTES.DASHBOARD, element: <Dashboard/>, roles: [ROLES.SUPPORT]},
	{route: ROUTES.QUESTIONNAIRES, element: <QuestionnairesList/>, roles: [ROLES.SUPPORT, ROLES.AML]},
	{route: ROUTES.COUNTRIES, element: <RegCountries/>},
	{route: ROUTES.USERS, element: <Users/>, roles: [ROLES.SUPPORT]},
]);
const unauthorizedRoutes = Object.freeze([
	{route: ROUTES.SIGN_IN, element: <Login/>},
]);
const AppRouter = ({user}) => {
	const defaultRoute = useMemo(() => {
		if (user.role === ROLES.AML) return ROUTES.QUESTIONNAIRES.path;
		else return ROUTES.DASHBOARD.path;
	}, [user]);

	const roleRoutes = useMemo(() => {
		if (user && user.role) {
			const role = user.role;
			if (role === ROLES.ADMIN) return authorizedRoutes;
			else return authorizedRoutes.filter(route => route?.roles?.includes(user.role));
		}
		return [];
	}, [user]);

	return (
		<Routes>
			{Boolean(API.getKey() && user.authKey) ? (
				<React.Fragment>
					{roleRoutes.map(route => (
						<Route key={route.route.key} path={route.route.path} element={route.element} exact/>
					))}
					<Route path="*" element={<Navigate to={String(defaultRoute)}/>}/>
				</React.Fragment>

			) : (
				<React.Fragment>
					{unauthorizedRoutes.map(route => (
						<Route key={route.route.key} path={route.route.path} element={route.element} exact/>
					))}
					<Route path="*" element={<Navigate to={ROUTES.SIGN_IN.path}/>}/>
				</React.Fragment>
			)
			}
		</Routes>
	);
};

const mapStateToProps = state => ({
	user: getUser(state),
})
export default connect(mapStateToProps)(AppRouter);
