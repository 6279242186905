import {PURGE} from "redux-persist/es/constants";

const initialState = {
	fee: null,
	course: null,
	min_sum: null,
	max_sum: null,
}

const tradeReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'Trade/SET': {
			return {...state, ...action.payload};
		}

		case PURGE: {
			return initialState;
		}

		default:
			return state;
	}
};

export default tradeReducer;
