import {Layout} from "antd";
import React from "react";
import AppRouter from "../AppRouter/AppRouter";
import NavBar from "../layout/NavBar/NavBar";

const AppContent = () => {
	return (
		// <div className="app-wrapper">
			<Layout className="app-content">
				<NavBar/>
				<Layout.Content>
					<AppRouter/>
				</Layout.Content>
				{/*<Footer/>*/}
			</Layout>
		// </div>
	)
}
export default AppContent;
