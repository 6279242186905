import axios from "axios";

const suffix = '/api';
const $host = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL + suffix,
	timeout: 20000,
	headers: {
		'Content-Type': 'application/json',
	},
});

const API = {
	get: async (url, config) => {
		try {
			const request = await $host.get(url, config);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error({error});
			throw error.response;
		}
	},
	post: async (url, config) => {
		try {
			const request = await $host.post(url, config);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error({error});
			throw error.response;
		}
	},
	put: async (url, config) => {
		try {
			const request = await $host.put(url, config);
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error(error);
			throw error.response;
		}
	},
	delete: async (url, config) => {
		try {
			const request = await $host.delete(url, { data: config });
			return { data: request.data, headers: request.headers, status: request.status };
		} catch (error) {
			console.error(error);
			throw error.response;
		}
	},
	setKey: key => {
		$host.defaults.headers.common.token = key;
	},
	getKey: () => {
		return $host.defaults.headers.common.token;
	},
};

export {
	API,
};
