import {persistReducer, persistStore} from "redux-persist";
import {encryptTransform} from "redux-persist-transform-encrypt";
import storage from 'redux-persist/lib/storage';
import {combineReducers} from "redux";
import userReducer from "./reducers/userReducer";
import appReducer from "./reducers/appReducer";
import tradeReducer from "./reducers/tradeReducer";
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import {IS_DEV} from "../utils/constants";
import {initMiddleware} from "../middlewares/initMiddleware";
import {configureStore} from "@reduxjs/toolkit";

const encryptor = encryptTransform({
	secretKey: 'cryptolite-office-secret-key-storage-phrase',
	onError: function (error) {
		console.error('Error while encrypting:', error);
	},
});

const userPersistConfig = {
	key: 'user',
	storage: storage,
	transforms: [encryptor],
};

const appPersistConfig = {
	key: 'app',
	storage: storage,
};

const rootReducer = combineReducers({
	user: persistReducer(userPersistConfig, userReducer),
	app: persistReducer(appPersistConfig, appReducer),
	trade: tradeReducer,
});

const middleware = [thunkMiddleware, initMiddleware];
if (process.env.NODE_ENV === 'development' && IS_DEV) middleware.push(loggerMiddleware);

const store = configureStore({
	reducer: rootReducer,
	middleware,
});

const persistor = persistStore(store);

export { store, persistor };
