import {API} from "../../http";
import {NEW_ENDPOINTS} from "../../http/endpoints";
import {message} from "antd";

export const login = (formData) => async (dispatch) => {
	try {
		const {data} = await API.post(NEW_ENDPOINTS.AUTH.LOGIN, {
			login: formData.login,
			password: formData.password,
		});
		if (data.token) {
			// success login
			API.setKey(data.token);
			dispatch({type: 'User/SET', payload: {authKey: data.token, role: data.role}});
		} else message.error('Login error');
	} catch (e) {
		message.error('Login error');
		throw e;
	}
}
