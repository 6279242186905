import React, {useMemo} from 'react';
import {Layout, Menu} from "antd";
import classes from './NavBar.module.scss';
import {Logo} from "../../../assets/svg/logo";
import Icon, {TeamOutlined, TransactionOutlined, UserOutlined} from "@ant-design/icons";
import {ROUTES} from "../../AppRouter/AppRouter";
import {Link, useNavigate} from "react-router-dom";
import {useEnhancedLocation} from "../../../hooks/useEnhancedLocation";
import {logout} from "../../../store/actions/app";
import {getUser} from "../../../store/selectors";
import {connect} from "react-redux";
import {ROLES} from "../../../utils/constants";

const NavBar = ({user}) => {
    const location = useEnhancedLocation();
    const navigate = useNavigate();

    const items = useMemo(() => {
        return [
            user.role !== ROLES.AML && {
                label: 'Transactions',
                key: ROUTES.DASHBOARD.key,
                icon: <TransactionOutlined/>,
            },
            {
                label: 'Users',
                icon: <TeamOutlined/>,
                children: [
                    {
                        label: 'View list',
                        key: ROUTES.USERS.key,
                        disabled: user.role === ROLES.AML,
                    },
                    {
                        label: 'Reg. countries',
                        key: ROUTES.COUNTRIES.key,
                        disabled: user.role !== ROLES.ADMIN,
                    },
                    {
                        label: 'Questionnaires',
                        children: [
                            {
                                label: 'View list',
                                key: ROUTES.QUESTIONNAIRES.key,
                            },
                            {
                                label: 'Edit structure',
                                disabled: true,
                            },
                        ]
                    },
                ],
            },
            {
                label: 'Account',
                key: 'account',
                icon: <UserOutlined/>,
                children: [
                    {
                        label: 'Log out',
                        key: 'logout',
                        danger: true,
                    },
                ],
            },
        ];
    }, [user]);

    const handleClick = ({key}) => {
        if (key === 'logout') return logout();
        else {
            const route = Object.values(ROUTES).find(route => route.key === key);
            if (route) navigate(route.path);
        }
    };

    return (
        <Layout.Header className={classes.container}>
            <Link style={{ height: '100%' }} to={ROUTES.DASHBOARD.path}>
                <Icon style={{marginRight: 10}} component={Logo}/>
            </Link>
            <Menu style={{minWidth: 0, flexGrow: 1, justifyContent: 'flex-end'}} onClick={handleClick}
                  selectedKeys={[location.routeKey]} mode="horizontal" items={items}/>
        </Layout.Header>
    );
};
const mapStateToProps = state => ({
    user: getUser(state),
})
export default connect(mapStateToProps)(NavBar);
