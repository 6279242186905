import React from 'react';
import classes from './Container.module.scss';
import {combineClasses} from "../../../utils/helpers";

const Container = ({children, className, ...props}) => {
	return (
		<div className={combineClasses(classes.container, className)} {...props}>
			{children}
		</div>
	);
};

export default Container;
