import React, {useMemo, useState} from 'react';
import {Button, Checkbox, Flex, Image, message, Select, Space, Table, Tag, Typography} from "antd";
import mobIcon from '../../../../assets/svg/mob-icon.svg';
import webIcon from '../../../../assets/svg/web-icon.svg';
import {AML_STATUS, ROLES} from "../../../../utils/constants";
import {useColumnSearchProps} from "../../../../hooks/useColumnSearchProps";
import {updateAMLStatus} from "../../actions";
import {CloseCircleOutlined, EditOutlined, ReloadOutlined} from "@ant-design/icons";

const PLATFORM_TYPE = Object.freeze({
    MOBILE: 'MOBILE',
    WEB: 'WEB',
});

function determineIfMobile(id) {
    return /^\d+$/.test(id);
}

function renderPlatformTag(id) {
    const isMobile = determineIfMobile(id);
    if (isMobile) return (
        <Tag color="#000" bordered={false}>
            <Space>
                {PLATFORM_TYPE.MOBILE}
                <Image src={mobIcon} preview={false} width={12} height={12}/>
            </Space>
        </Tag>
    )
    else return (
        <Tag color="#000" bordered={false}>
            <Space>
                {PLATFORM_TYPE.WEB}
                <Image src={webIcon} preview={false} width={12} height={12}/>
            </Space>
        </Tag>
    )
}

export function renderAMLStatus(status) {
    switch (status) {
        case AML_STATUS.APPROVED:
            return <Tag color="success" bordered={false}>{AML_STATUS.APPROVED}</Tag>;
        case AML_STATUS.REJECTED:
            return <Tag color="error" bordered={false}>{AML_STATUS.REJECTED}</Tag>;
        case AML_STATUS.MANUAL:
            return <Tag color="processing" bordered={false}>{AML_STATUS.MANUAL}</Tag>;
        case AML_STATUS.NOT_CHECKED:
            return <Tag bordered={false}>{AML_STATUS.NOT_CHECKED}</Tag>;
        default:
            return <Tag>Unknown type</Tag>
    }
}

const QuestionnairesTable = ({questionnaires, role, dataLoading, setQuestionnaires, onActionView, fetchQuestionnaires}) => {
    const [loading, setLoading] = useState(false);
    const [editableId, setEditableId] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowKeysStatus, setSelectedRowKeysStatus] = useState(AML_STATUS.NOT_CHECKED);
    const [showOnlyCompleted, setShowOnlyCompleted] = useState(true);

    const {
        getColumnSearchProps,
        searchText,
        searchedColumn,
    } = useColumnSearchProps();

    const isLoading = useMemo(() => Boolean(loading || dataLoading), [loading, dataLoading]);
    const hasSelected = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys]);
    const filteredData = useMemo(() => {
        return showOnlyCompleted ? questionnaires.filter(i => i.aml_status && i.aml_status !== AML_STATUS.NOT_CHECKED) : questionnaires;
    }, [questionnaires, showOnlyCompleted]);

    const isAvailableToEdit = useMemo(() => {
        return [ROLES.ADMIN, ROLES.AML].includes(role);
    }, [role]);

    const onChangeStatus = (ids, aml_status) => {
        setLoading(true);
        const idsArray = Array.isArray(ids) ? ids : [ids];
        const updatePromises = idsArray.map(id =>
            updateAMLStatus(id, aml_status)
                .then(() => ({ id, aml_status }))
                .catch((error) => {
                    message.error(`Failed to update status for id ${id}`);
                    console.error(`Failed to update status for id ${id}:`, error);
                })
        );
        Promise.allSettled(updatePromises).then(results => {
            const successfulUpdates = results.filter(result => result.status === 'fulfilled').map(result => result.value);

            const updatedQuestionnaires = questionnaires.map(item => {
                const update = successfulUpdates.find(update => update.id === item.id);
                return update ? { ...item, aml_status: update.aml_status } : item;
            });

            setQuestionnaires(updatedQuestionnaires);
            setLoading(false);
            setSelectedRowKeys([]);
            setEditableId(undefined);
        }).catch(error => {
            message.error("An error occurred while updating statuses.");
            console.error("An error occurred while updating statuses:", error);
        });
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const onChangeShowOnlyCompleted = (e) => {
        setShowOnlyCompleted(e.target.checked);
        setSelectedRowKeys([]);
        setEditableId(undefined);
    }

    const columns = [
        {
            title: 'Platform',
            key: 'platform',
            width: 120,
            filters: [
                {text: PLATFORM_TYPE.MOBILE, value: PLATFORM_TYPE.MOBILE},
                {text: PLATFORM_TYPE.WEB, value: PLATFORM_TYPE.WEB},
            ],
            align: 'center',
            filterMode: 'menu',
            onFilter: (value, record) => {
                const isMobile = determineIfMobile(record.id);
                if (value === PLATFORM_TYPE.MOBILE && isMobile) return true;
                else return value === PLATFORM_TYPE.WEB && !isMobile;
            },
            render: (record) => {
                return renderPlatformTag(record.id);
            },
        },
        {
            title: 'Client ID',
            dataIndex: 'id',
            key: 'id',
            width: 350,
            ...getColumnSearchProps('id', (value) => <Typography.Text copyable={value}>{value}</Typography.Text>),
        },
        {
            title: 'E-mail',
            dataIndex: 'email_address',
            key: 'email_address',
            width: 250,
            ...getColumnSearchProps('email_address', (value) => <Typography.Text
                copyable={value}>{value}</Typography.Text>),
        },
        {
            title: 'AML STATUS',
            dataIndex: 'aml_status',
            key: 'aml_status',
            width: 150,
            filters: Object.values(AML_STATUS).map(i => ({text: i, value: i})),
            filterMode: 'menu',
            onFilter: (value, record) => value === record.aml_status,
            align: 'center',
            render: (value, record) => {
                if (record.id === editableId) return (
                    <Space>
                        <Select
                            defaultValue={value}
                            // bordered={false}
                            loading={isLoading}
                            style={{width: 120}}
                            onChange={(value) => onChangeStatus(record.id, value)}
                            options={Object.values(AML_STATUS).map(i => ({title: i, value: i}))}
                        />
                        <CloseCircleOutlined onClick={() => setEditableId(undefined)} style={{cursor: 'pointer'}}/>
                    </Space>

                )
                return isAvailableToEdit ? (
                  <div onClick={() => setEditableId(record.id)} style={{cursor: 'pointer'}}>
                      <Space>
                          {renderAMLStatus(value)}
                          <EditOutlined/>
                      </Space>
                  </div>
                ) : renderAMLStatus(value)
            },
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (record) => <Typography.Link onClick={() => onActionView(record)}>View</Typography.Link>,
        },
    ];
    return (
        <Space direction="vertical">
            <Space>
                <Checkbox disabled={isLoading} checked={showOnlyCompleted} onChange={onChangeShowOnlyCompleted}>Show
                    only completed</Checkbox>
                <Typography.Text type="secondary">{filteredData.length} / {questionnaires.length}</Typography.Text>
            </Space>
            <Flex justify="space-between" gap={30} className="w100" align="baseline">
                <Space>
                    <Select
                        loading={isLoading}
                        style={{width: 150}}
                        value={selectedRowKeysStatus}
                        onChange={setSelectedRowKeysStatus}
                        disabled={!hasSelected}
                        options={Object.values(AML_STATUS).map(i => ({title: i, value: i}))}
                        defaultValue={AML_STATUS.NOT_CHECKED}
                    />
                    <Button onClick={() => onChangeStatus(selectedRowKeys, selectedRowKeysStatus)}
                            disabled={!hasSelected}>Update status</Button>
                    {hasSelected &&
                        <Typography.Text type="secondary">Selected: {selectedRowKeys.length}</Typography.Text>}
                </Space>
                <Button icon={<ReloadOutlined/>} onClick={fetchQuestionnaires} shape="round" type="primary"/>
            </Flex>
            <Table showSorterTooltip={false} rowSelection={isAvailableToEdit && {
                selectedRowKeys,
                onChange: onSelectChange,
            }} pagination={{position: ['none', 'bottomCenter'], hideOnSinglePage: true}}
                   loading={isLoading} dataSource={filteredData} scroll={{x: 1000}} columns={columns}/>
        </Space>
    );
};

export default QuestionnairesTable;
